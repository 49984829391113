import image0 from '../../images/photos/IMG_033013.jpg';
import image1 from '../../images/photos/05.jpg';
import image2 from '../../images/photos/IMG_028305.jpg';

import image3 from '../../images/photos/IMG_068118.jpg';
import image4 from '../../images/photos/12.jpg';
import image5 from '../../images/photos/2024101308.jpg';

import image6 from '../../images/photos/IMG_028906 PHOTO_@vovaphint.jpg';
import image7 from '../../images/photos/08.jpg';
import image8 from '../../images/photos/IMG_036927 PHOTO_@vovaphint.jpg';

import image9 from '../../images/photos/IMG_196323.jpg';
import image10 from '../../images/photos/IMG_195119.jpg';
import image11 from '../../images/photos/IMG_199832.jpg';

import image12 from '../../images/photos/IMG_042203.jpg';
import image13 from '../../images/photos/IMG_052105.jpg';
import image14 from '../../images/photos/02.jpg';

import image15 from '../../images/photos/IMG_041937 PHOTO_@vovaphint.jpg';
import image16 from '../../images/photos/IMG_035519.jpg';
import image17 from '../../images/photos/IMG_013115.jpg';

export const images = [
  image0,
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
];

export const title = 'Примеры фотографий';
