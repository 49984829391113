import { Helmet } from 'react-helmet-async';
import { articlesList } from '../Blog/consts';
import './BlogArticle.css';
import { useParams } from 'react-router-dom';

export default function BlogArticle() {
  let { articleId } = useParams();

  const item = articlesList.find(({ link }) => link === articleId);
  const { image, title, articleText, articleList, articleTextTwo, metalink } = item;

  const articleTextItems = articleText.map((text, index) => (
    <p className='article__text' key={index}>
      {text}
    </p>
  ));

  const articleListItems = articleList.map((item, index) => (
    <li className='article__item' key={index}>
      {item}
    </li>
  ));

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={articleText} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={articleText} />
        <meta property='og:url' content={metalink} />
        <link rel='canonical' href={metalink} />
      </Helmet>
      <article className='article'>
        <h1 className='article__title'>{title}</h1>
        <img src={image} alt={title} className='article__image' />

        {articleTextItems}

        <ul className='article__list'>{articleListItems}</ul>

        {articleTextTwo && <p className='article__text'>{articleTextTwo}</p>}
      </article>
    </>
  );
}
