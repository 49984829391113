import './Photos.css';
import Photo from '../Photo/Photo';

export default function Photos({ images, title, onCardClick }) {
  return (
    <>
      <section className='photo'>
        <h2 className='photo__title'>{title}</h2>
        <ul className='photo__list'>
          {images.map((image, index) => (
            <Photo key={index} image={image} onCardClick={onCardClick} />
          ))}
        </ul>
      </section>
    </>
  );
}
