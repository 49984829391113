import './Photo.css';

export default function Photo({ image, onCardClick }) {
  function handleCardClick() {
    onCardClick(image);
  }

  return (
    <li className='photo__item'>
      <img
        src={image}
        alt='Пример фотосъёмки интерьера'
        className='photo__image'
        onClick={handleCardClick}
      />
    </li>
  );
}
