import Message from '../Message/Message';
import Questions from '../Questions/Questions';
import { Helmet } from 'react-helmet-async';
import { title, description, link } from './consts';

export default function Contacts() {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        <meta property='og:url' content={link} />
        <link rel="canonical" href={link} />
      </Helmet>
      <Questions />
      <Message />
    </>
  );
}
