import './ImagePopup.css';

export default function ImagePopup({ card, onClose }) {
  return (
    <section
      className={`popup popup_image ${card && 'popup_opened'}`}
      aria-label='Просмотр фотографии'
    >
      <figure className='popup__figure'>
        <button
          type='button'
          aria-label='close button'
          className='popup__button-close'
          onClick={onClose}
        />
        <img src={card} alt='Пример фотографии интерьера' className='popup__image' />
      </figure>
    </section>
  );
}
