import { Helmet } from 'react-helmet-async';
import { SERVICE_ITEMS, title, titleHelmet, description, link } from './consts';
import './Services.css';

export default function Services() {
  const serviceItems = SERVICE_ITEMS.map(
    ({ id, figureTitle, figcaption, listTitle, serviceList, articleNote }) => (
      <li key={id} className='service__item'>
        <article className='service__article'>
          <figure className='service__figure'>
            <h2 className='service__figure-title'>{figureTitle}</h2>
            <figcaption className='service__figcaption'>{figcaption}</figcaption>
          </figure>

          <h3 className='service__list-title'>{listTitle}</h3>
          <ul className='service__article-list'>
            {serviceList.map((item, index) => (
              <li key={index}>
                <p className='service__article-text'>{item}</p>
              </li>
            ))}
          </ul>
          <p className='service__article-note'>{articleNote}</p>
        </article>
      </li>
    ),
  );

  return (
    <>
      <Helmet>
        <title>{titleHelmet}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={titleHelmet}></meta>
        <meta property='og:description' content={description}></meta>
        <meta property='og:url' content={link} />
        <link rel="canonical" href={link} />
      </Helmet>
      <section className='service'>
        <h1 className='service__title'>{title}</h1>

        <ul className='service__list'>{serviceItems}</ul>
        {/* <p className='service__note'>{note}</p> */}
      </section>
    </>
  );
}
