import './PageNotFound.css';
import { NavLink } from 'react-router-dom';
import { pageNotFound } from './consts';
import { Helmet } from 'react-helmet-async';

export default function PageNotFound() {
  const { title, subtitle, text, buttonText } = pageNotFound;

  return (
    <>
      <Helmet>
        <title>
          {title}
          {subtitle}
        </title>
        <meta name='description' content={text} />
        <meta property='og:title' content={title}></meta>
        <meta property='og:description' content={text}></meta>
      </Helmet>
      <section className='notfound'>
        <h1 className='notfound__title'>{title}</h1>
        <h2 className='notfound__subtitle'>{subtitle}</h2>
        <p className='notfound__text'>{text}</p>
        <NavLink to='/' className='notfound__link'>
          {buttonText}
        </NavLink>
      </section>
    </>
  );
}
