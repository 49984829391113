import './Home.css';
import { useState, useEffect } from 'react';
import Lead from '../Lead/Lead';
import AboutMe from '../AboutMe/AboutMe';
import Dignity from '../Dignity/Dignity';
import PhotoGallery from '../PhotoGallery/PhotoGallery';
import ImagePopup from '../ImagePopup/ImagePopup';

import { Helmet } from 'react-helmet-async';
import { description, title, link } from './consts';

export default function Home() {
  useEffect(() => {
    function handleEscClose(e) {
      const ESC_CODE = 'Escape';
      if (e.key === ESC_CODE) {
        closePopup();
      }
    }
    document.addEventListener('keydown', handleEscClose);
    return () => document.removeEventListener('keydown', handleEscClose);
  }, []);

  const [selectedCard, setSelectedCard] = useState(null);

  function handleCardClick(data) {
    setSelectedCard(data);
  }

  function closePopup() {
    setSelectedCard(null);
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:title' content={title}></meta>
        <meta property='og:description' content={description}></meta>
        <meta property='og:url' content={link} />
        <link rel="canonical" href={link} />
      </Helmet>
      <Lead />
      <AboutMe />
      <Dignity />
      <PhotoGallery onCardClick={handleCardClick} />
      <ImagePopup card={selectedCard} onClose={closePopup} />
    </>
  );
}
