import './AboutMe.css';
import image from '../../images/vova/IMG_0174.jpg';
import AboutTable from '../AboutTable/AboutTable';
import { aboutText, title } from './consts';

export default function AboutMe() {
  const figcaptionText = aboutText.map((item, index) => (
    <p className='aboutme__text' key={index}>
      {item}
    </p>
  ));

  return (
    <section className='aboutme' aria-label='Обо мне'>
      <AboutTable />
      <h2 className='aboutme__title'>{title}</h2>

      <article className='aboutme__article'>
        <figure className='aboutme__figure'>
          <figcaption className='aboutme__figcaption'>{figcaptionText}</figcaption>
          <img src={image} alt='Владимир Филиппов' className='aboutme__image' />
        </figure>
      </article>
    </section>
  );
}
